import { render, staticRenderFns } from "./english-big-effect-evaluation.vue?vue&type=template&id=2612e995&scoped=true&"
import script from "./english-big-effect-evaluation.vue?vue&type=script&lang=js&"
export * from "./english-big-effect-evaluation.vue?vue&type=script&lang=js&"
import style0 from "./english-big-effect-evaluation.vue?vue&type=style&index=0&id=2612e995&lang=scss&scoped=true&"
import style1 from "./english-big-effect-evaluation.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2612e995",
  null
  
)

export default component.exports