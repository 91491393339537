<template>
  <b-container>
    <div class="answer-mock-test-back">
      <b-row class="mock-Test-title">
        <div class="test-title">培立优2023中考英语冲刺</div>
        <span class="countdownClass">
          <countdown @afterCountdown="afterCountdown"></countdown>
        </span>
      </b-row>
      <b-row>
        <!-- <b-col class="test-subtitle">{{ this.$route.query.examname }}</b-col> -->
        <b-col class="test-subtitle">沙漠行动（大模块）效果测评</b-col>
      </b-row>
      <b-row>
        <b-col class="item-title">选择题部分 共60分</b-col>
      </b-row>
      <div
        v-for="(section, index) in simulationExamQuestions"
        :key="index"
        class="test-item"
        :class="[ModuleTitleClass(section.sectionType)]"
      >
        <b-row class="module-title" align-v="center">
          <div class="section-title text-left">
            <span>{{ roman(index) }}.</span>
            {{ section.description }} （{{ section.sectionScore }}）
          </div>
          <div
            class="down-button"
            @click="getQuestions(index, section.sectionId)"
            v-if="section.sectionType !== 1"
            v-b-toggle="'mocktest-coll' + index"
          >
            <div v-if="unfold[index]">
              <span>展开试题</span>
              <span class="iconfont icon-jiantou11"></span>
            </div>
            <div v-if="!unfold[index]">
              <span>隐藏试题</span>
              <span class="iconfont icon-jiantou1"></span>
            </div>
          </div>
          <b-col
            class="completeClass"
            cols="3"
            v-if="section.sectionType === 1"
          >
            <div class="complete-listening">
              <span class="iconfont iconerji"></span>
              <span>已完成听力测试</span>
            </div>
          </b-col>
          <b-col cols="12">
            <b-collapse
              class="written-expression-title"
              :id="'mocktest-coll' + index"
            >
              <EnglishTestQuestion
                v-for="examQuestion in section.simulationSections"
                :key="examQuestion.plyTkSectionId"
                :examQuestion="examQuestion"
                :section="section"
                :index="index"
                :isSelfScores="isSelfScores"
                :userAnswers="userAnswers"
                :totalPrice="totalPrice"
                :selfEvaluation="selfEvaluation"
                @changeRadio="changeRadio"
                @getInput="getInput"
                @textareaChange="textareaChange"
                @afterRefreshImg="afterRefreshImg"
                @scoreCheck="scoreCheck"
                @openSelfScore="openSelfScore"
              >
              </EnglishTestQuestion>
            </b-collapse>
          </b-col>
        </b-row>
        <b-row v-if="section.sectionType === 5">
          <b-col class="item-title">非选择题部分 共60分</b-col>
        </b-row>
      </div>
      <div class="exam-questions">
        <b-button class="submit-test-paper" @click="submitTestButton">
          <span class="iconfont iconsubmit-fill"></span>
          <span>提交试卷</span>
        </b-button>
        <b-button class="giveUpTheExam" @click="cancleTestButton">
          <span class="iconfont iconfangqi"></span>
          <span>放弃考试</span>
        </b-button>
      </div>
      <GlobalPopup
        :popupsTitle="cancleTestTitle"
        :popupsbuttomA="cancleTestbuttomA"
        :popupsbuttomB="cancleTestbuttomB"
        @OKbutton="okSelfScore"
        @NObutton="
          () => {
            modal1 = false;
          }
        "
        :isShow="modal1"
      ></GlobalPopup>
      <GlobalPopup
        :popupsTitle="cancleTestTitle"
        :popupsbuttomA="cancleTestbuttomA"
        :popupsbuttomB="cancleTestbuttomB"
        @OKbutton="cancleTestNObutton"
        @NObutton="cancleTest"
        :isShow="modal3"
      ></GlobalPopup>
      <GlobalPopup
        :popupsTitle="submitTestTitle"
        :popupsbuttomA="submitTestbuttomA"
        :popupsbuttomB="submitTestbuttomB"
        @OKbutton="submitTest"
        @NObutton="thinkAgainButton"
        :isShow="modal4"
      ></GlobalPopup>
      <GlobalPopup
        :popupsTitle="svaluationTitle"
        :popupsbuttomA="svaluationbuttomA"
        :popupsbuttomB="svaluationbuttomB"
        @OKbutton="svaluationTest"
        @NObutton="
          () => {
            modal5 = false;
          }
        "
        :isShow="modal5"
      ></GlobalPopup>
      <GlobalScoreModal
        :isShowEvaluation="isShowEvaluation"
        :engSelfQuestions="engSelfQuestions"
        :isShowTable="isShowTable"
        :resultModelEssay="resultModelEssay"
        :totalPrice="totalPrice"
        @submitRating="submitRating"
        @modelEssay="modelEssay"
        @changeArticle="changeArticle"
      >
      </GlobalScoreModal>
    </div>
  </b-container>
</template>

<script>
import { getStudentEnglishAnswerPicture } from "@/api/english-mock";
import {
  getEngSelfQuestion,
  saveEngSelfQuestion,
} from "../../api/english-examination";
import {
  getExamSectionsAsync,
  getEngExamQuestionInfos,
  saveEngExam,
} from "@/api/english-big.js";
import { mapMutations, mapState } from "vuex";
import GlobalScoreModal from "../../components/global-popup/global-score-modal.vue";

import GlobalPopup from "@/components/global-popup/global-popup-modal";
import countdown from "@/components/countdown/countdown.vue";
import EnglishTestQuestion from "@/components/englinsh-test/english-test-quesions.vue";
export default {
  name: "MockTest",
  data() {
    return {
      sections: "",
      selfAnswerImg: "",
      textareaAnswers: this.getWriterAnswers(),
      studentExamId: "",
      answers: "",
      unfold: [true, true, true, true, true, true, true, true],
      isSelfScores: [true, true, true, true, true, true, true, true, true],
      writeSelfScore: true,
      cancleTestTitle: "你正在离开答题区，离开后下次进入将重新开始测试！",
      cancleTestbuttomA: "",
      cancleTestbuttomB: "继续测试",
      submitTestTitle: "确定现在提交答案吗？提交后将无法修改！",
      submitTestbuttomA: "再想想",
      submitTestbuttomB: "现在提交",
      svaluationTitle: "确定提交自评分",
      svaluationbuttomA: "暂不提交",
      svaluationbuttomB: "确定提交",
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      selfScoreIndex: "",
      selfScoreType: "",
      isSubmitPic: false,
      isSubmitTest: false,
      leaveIllegally: false,
      isShowEvaluation: false,
      engSelfQuestions: [],
      isShowTable: false,
      totalPrice: 0,
      resultModelEssay: "",
      compositionQuestionId: "",
      selfEvaluation: false,
      toName: "",
    };
  },
  components: {
    countdown,
    GlobalPopup,
    EnglishTestQuestion,
    GlobalScoreModal,
  },
  computed: {
    ...mapState([
      "simulationExamQuestions",
      "userAnswers",
      "testEndTime",
      "examId",
    ]),
    // textareaAnswers:function(){
    //   if(sessionStorage.getItem('textareaAnswers')){
    //     return JSON.parse(sessionStorage.getItem("textareaAnswers"))
    //   }
    //   return {}
    // }
  },
  mounted() {
    if (!sessionStorage.getItem("simulationExamQuestions")) {
      this.getSections();
    } else {
      this.sections = this.simulationExamQuestions;
      this.answers = this.userAnswers;
    }
    if (sessionStorage.getItem("values")) {
      this.getOldInputAnswer();
    }
    if (sessionStorage.getItem("isSelfScores")) {
      this.isSelfScores = JSON.parse(sessionStorage.getItem("isSelfScores"));
    }
    if (sessionStorage.getItem("writeSelfScore")) {
      this.writeSelfScore = JSON.parse(
        sessionStorage.getItem("writeSelfScore")
      );
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.leaveIllegally) {
      this.toName = to.name;
      console.log("to.path", to.path);
      if (to.path == "/login") {
        return (this.leaveIllegally = true);
      } else {
        this.cancleTestTitle = "是否确认放弃体检，已提交答案会作废？";
        this.cancleTestbuttomA = "确定放弃";
        this.cancleTestbuttomB = "再想想";
        this.modal3 = true;
        return this.leaveIllegally;
      }
    }
    this.setSimulationExamQuestions(null);
    this.setUserAnswers(null);
    sessionStorage.removeItem("userAnswers");
    sessionStorage.removeItem("simulationExamQuestions");
    sessionStorage.removeItem("values");
    sessionStorage.removeItem("textareaAnswers");
    sessionStorage.removeItem("testEndTime");
    sessionStorage.removeItem("isSelfScores");
    sessionStorage.removeItem("writeSelfScore");
    sessionStorage.removeItem("listeningBeginTime");
    sessionStorage.removeItem("listeningAnswers");
    sessionStorage.removeItem("addressImg");
    next();
  },
  methods: {
    ...mapMutations([
      "setSimulationExamQuestions",
      "setUserAnswers",
      "setTestEndTime",
    ]),
    scoreCheck(tg, maxScore) {
      console.log("失去焦点", tg);
      if (tg.target.value > maxScore) {
        tg.target.value = maxScore;
      }
      if (tg.target.value < 0) {
        tg.target.value = 0;
      }
    },
    afterRefreshImg() {
      this.isSubmitPic = true;
    },
    changeArticle(event, index) {
      console.log("event, index", event, index);
      this.scoreAnswer.selfAnswerInput[index].answerKey = event;
      let arr = this.engSelfQuestions[index].options;
      arr.find((obj) => {
        if (event == obj.value) {
          this.scoreAnswer.selfAnswerInput[index].selfScore = obj.score;
        }
      });
      this.sum(this.scoreAnswer.selfAnswerInput);
    },
    sum(arr) {
      console.log(arr);
      // this.totalPrice = arr.reduce((p, e) => p + e.selfScore, 0);
      var strarr = [];
      for (let i in arr) {
        strarr.push(arr[i].selfScore);
      }
      this.totalPrice = eval(strarr.join("+"));
    },
    okSelfScore() {
      this.modal1 = false;
      if (this.selfScoreType === 20 && !this.isSubmitPic) {
        return;
      }
      if (this.selfScoreType === 20) {
        this.writeSelfScore = false;
        sessionStorage.setItem(
          "writeSelfScore",
          JSON.stringify(this.writeSelfScore)
        );
        this.isShowEvaluation = true;
        const parmeter = {
          questionId: this.compositionQuestionId,
        };
        getEngSelfQuestion(parmeter).then((res) => {
          console.log("自评分试题", res);
          this.resultModelEssay = res.data.result.modelEssay;
          this.engSelfQuestions = res.data.result.engSelfQuestions;
          this.scoreAnswer = {
            questionId: this.compositionQuestionId,
            selfAnswerInput: [],
          };

          for (const a of this.engSelfQuestions) {
            this.scoreAnswer.selfAnswerInput.push({
              selfQuestionId: a.selfQuestionId, //试题主键
              selfScore: "0", //单个自评分
              answerKey: "", //答案
            });
          }
        });
        return;
      }
      this.$set(this.isSelfScores, this.selfScoreIndex, false);

      sessionStorage.setItem("isSelfScores", JSON.stringify(this.isSelfScores));
    },
    svaluationTest() {
      saveEngSelfQuestion(this.scoreAnswer).then((res) => {
        console.log("提交自评分", res);
        this.selfEvaluation = res.data.result;
        this.modal5 = false;
        this.modal1 = false;
        this.isShowEvaluation = false;
        this.setSelfScore();
      });
    },
    setSelfScore() {
      let answers = this.userAnswers;
      let getAddressImg = sessionStorage.getItem("addressImg");
      for (let a of answers.answerSectionInputs) {
        if (a.sectionType === 8) {
          a["answerQuestionInputs"][a["answerQuestionInputs"].length - 1][
            "selfScore"
          ] = this.totalPrice;
          a["answerQuestionInputs"][a["answerQuestionInputs"].length - 1][
            "answer"
          ] = getAddressImg;
        }
      }
      this.setUserAnswers(answers);
    },
    submitRating() {
      // debugger;
      this.modal5 = true;
    },
    openSelfScore(index, type, questionId) {
      this.selfScoreIndex = index;
      this.selfScoreType = type;
      this.compositionQuestionId = questionId;
      if (type === 20 && !this.isSubmitPic) {
        this.cancleTestbuttomA = "";
        this.cancleTestbuttomB = "我知道了";
        this.cancleTestTitle = "请上传图片后再进行自评分！";
        this.modal1 = true;
        return;
      }

      this.cancleTestbuttomA = "再想想";
      this.cancleTestbuttomB =
        type === 20
          ? "现在自评"
          : !this.userAnswers.answerSectionInputs[5].answerQuestionInputs[index]
              .answer
          ? ""
          : "现在自评";
      this.cancleTestTitle =
        type === 20
          ? "是否现在开始自评？确定后将不可重新上传图片！"
          : !this.userAnswers.answerSectionInputs[5].answerQuestionInputs[index]
              .answer
          ? "当前题目未输入答案，请输入答案后再自评！"
          : "现在自评分吗？确定后将打开本题标准答案，供你作评分参考，但不能再修改答案！";
      this.modal1 = true;
    },
    roman(id) {
      if (id == 0) {
        return "Ⅰ";
      }
      if (id == 1) {
        return "Ⅱ";
      }
      if (id == 2) {
        return "Ⅲ";
      }
      if (id == 3) {
        return "Ⅳ";
      }
      if (id == 4) {
        return "Ⅴ";
      }
      if (id == 5) {
        return "Ⅵ";
      }
      if (id == 6) {
        return "Ⅶ";
      }
      if (id == 7) {
        return "Ⅷ";
      }
    },
    ModuleTitleClass(id) {
      if (id == 3) {
        return "listening-mock-cloze";
      }
      if (id == 4) {
        return "completing-the-dialogue";
      }
      if (id == 5) {
        return "choose-wordsA";
      }
      if (id == 6) {
        return "reading-comprehension";
      }
      if (id == 7) {
        return "choose-wordsB";
      }
      if (id == 8) {
        return "written-expression";
      }
    },
    afterCountdown() {
      this.submitTest();
    },
    refreshImg(sId, qId) {
      this.getStudentAnswer(sId, qId);
    },
    getWriterAnswers() {
      if (sessionStorage.getItem("textareaAnswers")) {
        return JSON.parse(sessionStorage.getItem("textareaAnswers"));
      }
      return {};
    },
    getOldInputAnswer() {
      const oldAnswers = JSON.parse(sessionStorage.getItem("values"));
      for (const key in oldAnswers) {
        const h = document
          .getElementsByClassName(key)[0]
          .getElementsByTagName("input");
        h.forEach((elem, index) => {
          elem.value = oldAnswers[key][index];
        });
      }
    },
    getStudentAnswer(sId, qId) {
      const parmeter = {
        qid: qId,
        studentExamId: this.userAnswers.studentExamId || this.examId,
      };
      getStudentEnglishAnswerPicture(parmeter).then(() => {});
    },
    cancleTest() {
      this.leaveIllegally = true;
      if (this.toName) {
        this.$router.replace({
          name: this.toName,
        });
      } else {
        this.$router.replace({
          name: "HomeIndex",
        });
      }
    },
    modelEssay() {
      this.isShowTable = !this.isShowTable;
    },
    buttonIcon() {
      if (this.isShowChart) {
        return "iconfont icon-jiantou11";
      } else {
        return "iconfont icon-jiantou1";
      }
    },
    getInput(index, questionId, sectionType) {
      const h = document
        .getElementsByClassName(questionId)[0]
        .getElementsByTagName("input");
      console.log("html答案", index, h);
      const values = Array.prototype.map.call(h, (item) => item.value);
      const sesValue = JSON.parse(sessionStorage.getItem("values"))
        ? JSON.parse(sessionStorage.getItem("values"))
        : {};
      values.push(sectionType);
      sesValue[questionId] = values;
      window.sessionStorage.setItem("values", JSON.stringify(sesValue));
    },
    textareaChange(value, index, questionId, state, score) {
      console.log("写作内容", value, index, questionId);
      if (value > score) {
        value = score;
      }
      if (value < 0) {
        value = 0;
      }
      for (const a of this.answers.answerSectionInputs[index]
        .answerQuestionInputs) {
        if (a.questionId === questionId) {
          if (state === 0) a.answer = value;
          if (state === 1) a.selfScore = value;
          break;
        }
      }
      sessionStorage.setItem(
        "textareaAnswers",
        JSON.stringify(this.textareaAnswers)
      );
      this.setUserAnswers(this.answers);
    },
    noFinishTest() {
      this.submitTestTitle =
        "你存在未作答试题或未自评分，是否现在提交？提交后将无法修改！";
      this.modal4 = true;
    },
    submitTestButton() {
      this.checkAnswers();
    },
    thinkAgainButton() {
      this.modal4 = false;
    },
    cancleTestButton() {
      this.cancleTestTitle = "是否确认放弃体检，已提交答案会作废？";
      this.cancleTestbuttomA = "确定放弃";
      this.cancleTestbuttomB = "再想想";
      this.modal3 = true;
    },
    cancleTestNObutton() {
      this.modal3 = false;
    },
    // 提交答案
    checkAnswers() {
      let submitOk = true;
      for (let i = 1; i < this.simulationExamQuestions.length; i++) {
        if (!this.simulationExamQuestions[i]["simulationSections"]) {
          submitOk = false;
          break;
        }
      }
      if (!submitOk) {
        this.noFinishTest();
        return;
      }
      let answers = this.answers.answerSectionInputs;
      for (let i = 1; i < answers.length; i++) {
        if (answers[i].sectionType === 8) {
          for (let a of answers[i].answerQuestionInputs) {
            if (!a.selfScore) {
              submitOk = false;
              break;
            }
          }
        }
        if ([6, 7].includes(answers[i].sectionType)) {
          const h = document.getElementsByClassName("chooseWords");
          for (let a of h) {
            for (let b of a.getElementsByTagName("input")) {
              if (!b.value) {
                submitOk = false;
                break;
              }
            }
            if (!submitOk) {
              break;
            }
          }
        } else {
          if (
            answers[i]["answerQuestionInputs"].length <
            this.simulationExamQuestions[i]["simulationSections"][0][
              "simulationExamQuestions"
            ].length
          ) {
            submitOk = false;
          }
        }
        if (!submitOk) {
          this.noFinishTest();
          return;
        }
        if (submitOk) {
          this.submitTestTitle = "确定现在提交答案吗？提交后将无法修改！";
          this.modal4 = true;
        }
      }
    },

    //提交答案
    submitTest() {
      if (this.isSubmitTest) return;
      this.isSubmitTest = true;
      const obj = JSON.parse(sessionStorage.getItem("values"));
      if (obj) {
        for (const key in obj) {
          var answer = "";

          for (let i = 0; i < obj[key].length - 1; i++) {
            if (i === obj[key].length - 2) {
              answer += obj[key][i];
            } else {
              answer += obj[key][i] + "|";
            }
          }
          // let index = obj[key][obj[key].length - 1] - 1;
          // this.answers.answerSectionInputs[index].answerQuestionInputs.push({
          //   questionId: key,
          //   answer: answer,
          // });
          for (let a of this.answers.answerSectionInputs) {
            if (a.sectionType == obj[key][obj[key].length - 1]) {
              a.answerQuestionInputs.push({
                questionId: key,
                answer: answer,
              });
            }
          }
        }
      }
      let remainingTime = this.testEndTime - new Date().getTime();
      if (remainingTime <= 0) {
        this.answers["answerMinutes"] = 110;
      } else {
        this.answers["answerMinutes"] = parseInt(
          110 - remainingTime / 1000 / 60
        );
      }

      const parmeter = this.answers;
      saveEngExam(parmeter).then((res) => {
        this.leaveIllegally = true;
        this.isSubmitTest = false;
        this.$router.replace({
          name: "BigModuleTestEnd",
          query: {
            // module: 2,
            studentExamId: res.data.result.studentExamId,
            sprintId: 4,
            stageId: this.$route.query.stage,
          },
        });
      });
    },
    changeRadio(value, questionId, setionId) {
      console.log("模拟试卷", this.answers);
      for (const a of this.answers.answerSectionInputs) {
        if (a.sectionId === setionId) {
          let isHave = false;
          for (const b of a.answerQuestionInputs) {
            if (b.questionId === questionId) {
              b.answer = value;
              isHave = true;
              break;
            }
          }
          if (!isHave) {
            a.answerQuestionInputs.push({
              questionId: questionId,
              answer: value,
            });
          }
        }
      }
      this.setUserAnswers(this.answers);
    },
    getSections() {
      getExamSectionsAsync({
        examId: this.$route.query.examId,
        sprintId: this.$route.query.sprintId,
      }).then((res) => {
        console.log("res", res);
        this.sections = res.data.result.simulationSections;
        this.setSimulationExamQuestions(this.sections);
        this.setTestEndTime(new Date().getTime() + 6300000);
        this.userAnswersInit(res.data.result.studentExamId);
      });
    },
    userAnswersInit(studentExamId) {
      this.answers = {
        examId: this.$route.query.examId,
        answerMinutes: 0,
        answerSectionInputs: [],
        studentExamId: studentExamId,
        sprintId: this.$route.query.sprintId,
        stageId: this.$route.query.stage,
      };
      for (const a of this.sections) {
        this.answers.answerSectionInputs.push({
          sectionId: a.sectionId,
          sectionType: a.sectionType,
          answerQuestionInputs: [],
        });
      }
      this.setUserAnswers(this.answers);
    },
    getQuestions(simIndex, sectionId) {
      this.$set(this.unfold, simIndex, !this.unfold[simIndex]);
      if (this.simulationExamQuestions[simIndex].simulationSections) {
        return;
      }
      getEngExamQuestionInfos({
        sectionId: sectionId,
        sprintId: this.$route.query.sprintId,
      }).then((res) => {
        this.$set(
          this.sections[simIndex],
          "simulationSections",
          res.data.result.simulationExamQuestionInfos
        );
        this.setSimulationExamQuestions(this.sections);
        if (this.sections[simIndex].sectionType === 8) {
          this.addWriteAnsers(
            simIndex,
            res.data.result.simulationExamQuestionInfos
          );
          // this.getMobileUrl(sectionId,res.result.simulationExamQuestionInfos)
        }

        console.log("模块试题", this.sections);
      });
    },
    addWriteAnsers(index, questions) {
      for (const a of questions) {
        for (const b of a.simulationExamQuestions) {
          this.answers.answerSectionInputs[index].answerQuestionInputs.push({
            selfScore: "",
            questionId: b.questionId,
            answer: "",
          });
          this.textareaAnswers[b.questionId] = {
            selfScore: "",
            answer: "",
          };
        }
      }
      this.setUserAnswers(this.answers);
      sessionStorage.setItem(
        "textareaAnswers",
        JSON.stringify(this.textareaAnswers)
      );
    },
    getSimulation(id) {
      if (this.simulationExamQuestions[id]) {
        return this.simulationExamQuestions[id].simulationExamQuestions;
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stem-statement {
  // padding: 2rem 0;
  padding-bottom: 1rem;
  text-align: left;
  // text-indent: 2rem;
  line-height: 2.5;
  padding-left: 15px;
}
.text-left {
  text-align: left;
}
.test-item {
  border-bottom: 2px solid #f0f0f0;
  padding: 1rem 0;
  .col-md-6 {
    padding-bottom: 1.5rem;
    & > span:nth-child(2) {
      line-height: 2;
      font-weight: 400 !important;
    }
  }
}
.section-title {
  font-weight: bold;
  font-size: 1.1429rem;
}
.test-title {
  font-size: 1.1429rem !important;
  font-weight: bold;
  text-align: right;
}
.test-subtitle {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2;
  margin-bottom: 2rem;
}
.item-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2eb976;
  margin: 2rem 0;
}
.testinfor-title {
  font-weight: bold;
  font-size: 1.1429rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  text-align: left;
  text-indent: 15px;
}
.answer-mock-test-back {
  min-height: 100vw;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 3rem 2rem;
  letter-spacing: 1px;
  line-height: 1.7;
}
.text-left {
  text-align: left;
}
.down-button {
  cursor: pointer;
  color: #2cb774;
  font-size: 1.1429rem;
  text-align: right;
  .iconjiantou1 {
    font-size: 14px;
  }
}
.test-prompt {
  font-size: 1.1429rem;
  text-indent: 2.5rem;
  line-height: 1.8;
}
.countdownClass {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.complete-listening {
  color: #fbaf4b;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 1.1429rem;
  .iconerji {
    font-size: 24px;
    padding-right: 3px;
  }
}
.completeClass {
  text-align: -webkit-right;
}
.chooseWordsContent {
  & > div {
    & > p {
      text-align: left;
      text-indent: 3rem;
      line-height: 2.5;
      padding-left: 15px;
    }
  }
  table {
    td {
      width: 500px !important;
    }
    p {
      margin: 0;
      padding: 2rem;
    }
  }
}
.exam-questions {
  padding-top: 7rem;
  font-weight: bold;
  button {
    padding: 0.5rem 1.5rem;
    font-size: 1.1429rem;
  }
}
.submit-test-paper {
  background: linear-gradient(0deg, #fbab43 0%, #fbb860 100%);
  border-radius: 5px !important;
  border: none !important;
  margin: 0 2rem;
  font-weight: bold;
  .iconsubmit-fill {
    padding-right: 2px;
  }
}
.giveUpTheExam {
  background: linear-gradient(0deg, #f0f0f0 0%, #e5e5e5 100%);
  border-radius: 5px !important;
  border: none !important;
  color: #333333 !important;
  margin: 0 2rem;
  font-weight: bold;
  .iconfangqi {
    color: #b1b0b0;
    padding-right: 2px;
  }
}
.choose-words {
  .question-statement {
    display: block;
  }
}
.mock-Test-title {
  position: relative;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  .test-title {
    display: flex;
    justify-content: center;
  }
  .countdownClass {
    position: absolute;
    right: 0;
  }
}
.module-title {
  width: 100%;
  margin: 0 auto !important;
  display: flex;
  justify-content: space-between;
  .written-expression-title {
    padding-top: 1.5rem;
  }
}

//阅读理解
.choose-wordsA {
  .testinfor-title {
    text-align: center;
  }
  .question-statement {
    padding-bottom: 1rem;
  }
  .mockTestContainer {
    padding: 0.3rem 15px;
  }
}
//补全对话
.completing-the-dialogue {
  .stem-statement {
    text-indent: 0px !important;
  }
  .mockTestContainer {
    padding: 0.5rem 10px;
  }
}
// 选词填空
.reading-comprehension {
  text-align: left;
  table {
    margin: 0 25%;
  }
  .chooseWordsContent {
    input {
      height: 22px !important;
      text-align: center !important;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      outline: none !important;
    }
  }
}
//七选五
.choose-wordsB {
  text-align: left;
  .chooseWordsContent {
    input {
      height: 22px !important;
      text-align: center !important;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      outline: none !important;
    }
  }
}
</style>
<style lang="scss">
.stem-statement {
  text-align: left;

  table {
    margin: 0 auto;
    * {
      margin: 0;
      padding: 0;
    }
    td {
      padding: 0 2em;
    }
  }
  // p {
  //   padding: 0px;
  //   margin: 0px;
  // }
}
.answer-mock-test-back {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2cb774 !important;
    background-color: #2cb774 !important;
  }
}
.choose-wordsB {
  .chooseWordsContent {
    line-height: 2.5;
    input {
      height: 22px !important;
      text-align: center !important;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      outline: none !important;
    }
  }
  table {
    p {
      padding-left: 1.429rem !important;
    }
  }
}
.reading-comprehension {
  table {
    margin: 0 auto;
    p {
      margin: 0;
    }
  }
  .chooseWordsContent {
    line-height: 2.5;
    input {
      height: 22px !important;
      text-align: center !important;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      outline: none !important;
    }
  }
}
.group-6,
.group-undefined {
  #mocktest-coll4 {
    & > div:nth-child(2) {
      .stem-statement {
        p {
          // text-align: center;
          img {
            width: 70%;
          }
        }
      }
    }
  }
}
//完形填空
.listening-mock-cloze {
  .question-option {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
}
</style>
